export const news = {
  title: '新闻资讯',
  desc: '与西图盟一起深度挖掘开发工业互联网世界',
  news_0: [
    {
      title: '国家数字化设计与制造创新中心揭牌',
      time: '2018.10.29',
      createTime: '来源：国家数字化设计与制造创新中心 公司新闻   发布时间：2018.10.29',
      overview: '10月25日，国家数字化设计与制造创新中心（以下简称“创新中心”）启动会及揭牌仪式举行，会议由湖北省经信委主任王祺扬主持.',
      descList: [
        {
          desc: '10月25日，国家数字化设计与制造创新中心（以下简称“创新中心”）启动会及揭牌仪式举行，会议由湖北省经信委主任王祺扬主持.',
        },
        {
          desc: '工业和信息化部副部长罗文、湖北省副省长曹广晶、武汉市市长周先旺和华中科技大学校长丁烈云共同为创新中心揭牌。',
        },
        {
          desc: '丁烈云感谢各位领导专家长期以来对学校的关心和帮助。他介绍，目前创新中心正积极稳步推进各项建设，创新中心内部公司化运作规范有序，创新联盟各成员单位合作日益加深，若干关键共性技术研发项目正处于深化论证和启动立项阶段。丁烈云表示，未来学校将继续大力支持创新中心的建设与发展，充分发挥创新中心在产学研协同创新中的作用，为提升我国数字化、智能化制造核心竞争力做出贡献。',
        },
        {
          desc: '曹广晶强调，湖北省政府将大力支持创新中心建设，全力做好保障工作，促进创新中心更多成果在省内孵化成长，为工业经济转型升级培育新动能。',
        },
        {
          desc: '罗文指出，在新形势下发展数字化智能化制造是构建制造业竞争优势的重要举措。他对进一步加快创新中心发展提出四点要求：一是抓好关键共性技术研发，突破行业共性技术瓶颈。二是坚持“企业+联盟”发展模式，加大整合行业创新资源力度。三是补齐工程化短板和弱项，抓好产业发展辐射带动能力建设。四是坚持市场化运作，加快形成自我造血、循环发展的商业模式。',
        },
        {
          desc: '在战略合作协议签约仪式上，创新中心董事长丁汉院士代表创新中心与中国运载火箭技术研究院、中国航发南方工业有限公司、北京动力机械研究所和南京博郡新能源汽车有限公司代表签约，副董事长丁荣军院士代表创新中心与中船重工第七〇一研究所、武汉华星光电技术有限公司、美好建筑装配科技有限公司、武汉船用机械有限责任公司和中国葛洲坝集团装备工业有限公司代表签约。',
        },
        {
          desc: '会后，罗文一行赴创新中心进行实地考察，听取了创新中心发展规划和建设进展情况汇报。',
        },
        {
          desc: '工业和信息化部科技司副司长范书建、装备司副巡视员王建宇，湖北省政府副秘书长胡道银，副校长张新亮，武汉市经信委党组书记姜铁兵、主任党蓁、副主任杨立祥，东湖新技术开发区管委会主任刘子清、副主任唐超，中国机械工程学会常务副理事长张彦敏，浙江大学谭建荣院士，华中科技大学熊有伦院士、李培根院士、李德群院士等专家，创新中心股东单位代表、联盟单位代表、战略合作单位代表、国家信息光电子、智能传感器、轻量化材料成形技术及装备三个创新中心及湖北省省级制造业创新中心培育单位代表等近200人参加启动会并参与考察。',
        },
      ],
      statement: ' 声明：转载此文是出于传递更多信息之目的。若有来源标注错误或侵犯了您的合法权益，请作者持权属证明与本网联系，我们将及时更正、删除，谢谢。',
    },
    {
      title: '经信新闻 | 省级制造业创新中心，3家由杭企牵头打造',
      time: '2019.11.5',
      createTime: '来源：杭州经信 发布时间：2019.11.5',
      src: require('@/assets/news/news_2.jpg'),
      overview: '10月31日，浙江省经济和信息化厅和浙江省财政厅联合发布2019年第167号文件，公布了2019年浙江省制造业创新中心创建名单。',
      descList: [
        {
          desc: '10月31日，浙江省经济和信息化厅和浙江省财政厅联合发布2019年第167号文件，公布了2019年浙江省制造业创新中心创建名单。',
        },
        {
          desc: '其中来自杭州的浙江吉利控股集团有限公司牵头的浙江省数字化设计与制造创新中心、杭州新松机器人自动化有限公司牵头的中国机器人及智能装备创新中心、亚太机电集团有限公司牵头的浙江省智能网联汽车创新中心正式获批。',
        },
        {
          imgSrc: require('@/assets/news/news_2.jpg'),
        },
        {
          desc: '浙江省数字化设计与制造创新中心',
        },
        {
          desc: '浙江省数字化设计与制造创新中心将围绕离散制造业数字化设计与制造工业软件开发，以汽车行业为重点，面向数字化双胞胎技术、工业物联网操作系统、C2M智能生产物流、制造业工业大数据中台及工业APP、C2M工业互联网协同制造平台等5各领域，解决智能制造关键共性技术难题，打造自主、安全、可控的工业软件生态产业。',
        },
        {
          desc: '吉利控股集团已发展成为一家集汽车整车、动力总成、关键零部件设计、研发、生产、销售及服务于一体，并涵盖出行服务、线上科技创新、金融服务、教育、赛车运动等业务在内的全球型集团。此外，吉利还稳健推进全球创新型科技企业的建设，逐步实现汽车制造商向移动出行服务商转变。',
        },
        {
          imgSrc: require('@/assets/news/news_detail_1_2.png'),
        },
        {
          desc: '中国机器人及智能装备创新中心重点打造两个平台：智能技术众包研发平台、解决方案展示推广平台；逐步拓展五项服务功能：大数据云服务、科技成果转移转化、科技创业孵化、高端人才培养、金融服务。力争5到10年发展成为国际知名、国内领先、行业领先的机器人及智能装备创新中心。',
        },
        {
          desc: '杭州新松机器人自动化有限公司是一家以机器人独有技术为核心，致力于数字化智能高端装备研发制造的国家高新技术企业，是浙江省唯一的机器人及智能装备整系统出口发达国家和地区的企业,主导业务涵盖机器人核心零部件、五大系列机器人产品、行业系统解决方案、工业4.0等四大业务板块，在全球五百强企业中得到批量应用。',
        },
        {
          desc: '中国机器人及智能装备创新中心已在萧山区机器人小镇落户。',
        },
        {
          imgSrc: require('@/assets/news/news_detail_1_3.png'),
        },
        {
          desc: '浙江省智能网联汽车创新中心由亚太机电集团有限公司牵头，联合浙大创新创业研究院、新吉奥集团、容大智造等7家高校、科研院所、企业共同发起，以联通浙江分公司、零跑汽车、华为通信、腾讯云等企业为支持而组建的智能网联汽车创新共同体。创新中心将以“引领浙江智能汽车产业发展”为目标，以智能网联汽车技术的研发、成果转化、试验、检测、产业化等功能与定位，带动浙江省智能网联汽车产业链条延伸和高端化升级，助推行业技术协同发展。',
        },
        {
          desc: '亚太机电集团是国汽智联研究院（国家级智能网联汽车创新中心）浙江省唯一股东单位，本次牵头成立浙江省智能网联创新中心，将与“国汽智联研究院”形成密切的交流与互补。',
        },
        {
          desc: '浙江省智能网联汽车创新中心将落户在由亚太机电集团筹建的亚太创新未来城。',
        },
      ],
    },
    {
      title: '2019年浙江省制造业创新中心创建名单新鲜出炉！',
      time: '2019.11.12',
      createTime: '来源：青年时报 作者：董菊芳 发布时间：2019.11.12',
      src: require('@/assets/news/news_3.jpg'),
      overview: '10月31日，浙江省经济和信息化厅和浙江省财政厅联合发布2019年第167号文件，公布了2019年浙江省制造业创新中心创建名单。',
      descList: [
        {
          desc: '为深入实施数字经济“一号工程”，进一步完善浙江制造业创新体系，全面提升自主创新能力，加快制造强省建设，省财政厅、省经信厅联合开展2019年浙江省制造业创新中心培养创建工作。',
        },
        {
          desc: '近日，在牵头单位自愿申请、地方政府部门推荐申报的基础上，经综合审查评审及网上公示等程序，共有5家单位被列入 2019年浙江省制造业创新中心创建名单。',
        },
        {
          imgSrc: require('@/assets/news/news_3.jpg'),
        },
        {
          imgSrc: require('@/assets/news/news_detail_3_1.png'),
        },
        {
          desc: '目前，全省已有15个制造业创新中心被列入创建单位、3个被列入培育单位。省财政对省级制造业创新中心创建单位分别给予1000万元补助（分2年到位），升级为国家级的将再给予3000万元补助。',
        },
        {
          imgSrc: require('@/assets/news/news_detail_2_2.png'),
        },
        {
          desc: '制造业创新中心以集聚有效创新资源为核心，以健全产学研用协同创新机制为手段，汇聚整合企业、科研院所、高等院校、高尖人才等资源优势，以需求为导向，打造贯穿创新链、产业链、资本链的制造业创新生态系统。坚持以面向行业（领域）发展所需的前沿共性技术，特别是“卡脖子”技术攻关为目标，以开展技术研发到转移扩散至首次商业化应用为导向进行培育创建。',
        },
      ],
      statement: '声明：转载此文是出于传递更多信息之目的。若有来源标注错误或侵犯了您的合法权益，请作者持权属证明与本网联系，我们将及时更正、删除，谢谢。',
    },
  ],
}
