<template>
  <div class="content">
    <div class="top"><Top :topData ="governmentService.topData"/></div>
    <div class="navigation"><Navigation :type="governmentService.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="governmentService.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="governmentService.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      governmentService: {
        topData: {
          title: '政务服务',
          text: '西图盟以IaaS为核心的云计算中心为政府应用提供有力的后台保障，以云平台实现政府部门间信息联动与政务工作协同，信息孤岛向互联互通转变。',
        },
        middleContenData: {
          img: '/programme/governmentService1.png',
          text: '随着大数据、人工智能的不断发展，以及移动互联网、物联网、云计算等技术的不断成熟，整个人类社会产生的数据呈现爆炸式增长。数据已经迅速成为全球各个国家和地区发展的核心生产资料。现如今，数据正在驱动各行各业变革新生，政府数字化转型也迫在眉睫。<br/> <br/> 政府数字化转型的过程中，需要充分利用先进的科学技术手段和社会各界的力量，加强顶层设计，积极促进政务服务的业务重组与流程再造。',
          middleData: [{
            icon: 'ic_delivery1',
            title: '纸质材料多且手续繁琐',
            text: '人工清单梳理漏洞多，导致办事效率下降',
          },
          {
            icon: 'ic_services1',
            title: '各部门间信息不流通',
            text: '垂直贯通、横向阻隔、纵强横弱，不同的部门间形成“数据烟囱”和“信息孤岛”',
          },
          {
            icon: 'ic_performance1',
            title: '事项标准化难统筹实现',
            text: '部门多、模板多、内容多，整体未能统筹管理',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: ' 借助 PaaS平台构建“数字政府”智慧大脑，按照标准统一规划、统一标准、统一建设和统一运营。为各类政务应用构建和政务服务提供基础性共用应用支撑。',
          bgImg: '/programme/governmentService2.png',
          bottomData: [
            'PAAS平台', ' 互联互通', '数据共享',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
