<template>
  <div class="wrapper">
    <div class="container">
      <span class="title">{{ newsDetail.title }}</span>
      <span v-if="newsDetail.createTime" class="create-time">{{ newsDetail.createTime }}</span>
      <ul class="news-container">
        <li
          class="news-desc"
          v-for="(item, index) in newsDetail.descList"
          :key="index"
        >
          <p v-if="item.desc">{{ item.desc }}</p>
          <img v-if="item.imgSrc" :src="item.imgSrc" alt="">
        </li>
      </ul>
      <p class="statement">{{ newsDetail.statement }}</p>
    </div>
  </div>
</template>

<script>
import { news } from '../data/news'

export default {
  name: 'news1',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      news,
    }
  },
  computed: {
    newsDetail () {
      return this.news.news_0[this.id]
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
  margin-top: 117px;
  padding-bottom: 40px;
}

.container {
  .g_center();
  height: 100%;
  overflow: hidden;
}

.news-page {
  width: 100%;
  height: auto;
  background: #FFFFFF;
}
.title {
  width: 100%;
  padding: 0 16px;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #393939;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0 24px;
}
.create-time {
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  color: #999999;
}
.news-container {
  width: 100%;
  .news-desc {
    position: relative;
    text-align: center;
    & > p {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #272727;
      flex: none;
      order: 4;
      flex-grow: 0;
      margin: 20px 0px;
      text-align: left;
      text-indent: 2em;
    }
    & > img {
      margin-top: 40px;
    }
  }
}
.statement {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #999999;
  text-align: left;
  padding: 0 16px 16px;
}
</style>
