<template>
    <div class="top">
    <div class="topContent">
      <ViewWrapper :size="size">
      <span class="title">{{topData.title}}</span> </ViewWrapper>
      <span class="text">{{topData.text}}</span>
    </div>
  </div>

</template>

<script>
import ViewWrapper from '@/components/ViewWrapper'
export default {
  components: {
    ViewWrapper,
  },
  props: {
    topData: {
      type: Object,
      default: function () {
        return ''
      },
    },
  },
  data () {
    return {
      size: 'is-large',
    }
  },

}
</script>

<style lang="less" scoped>
  .top{
  position: relative;
  height: 526px;
  min-width: 1080px;
  // width: 100%;
  background: url('/programme/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .topContent{
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 163px;
    height: 200px;
    .title{
    display: block;
    font-family:'微软雅黑';
    font-weight: 500;
    font-size: 50px;
    line-height: 70px;
    align-items: center;
    color: #000000;
    margin-bottom: 32px;
  }
  .text{
    font-family:'微软雅黑';
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #272727;
    max-width: 900px;
  }
  }
}
</style>
