<template>
  <div class="bottom" :style="{ backgroundImage: `url(${bottomDatas.bgImg})` }">
    <ViewWrapper :size="size">
      <div class="bottomContent">
      <span class="title">{{bottomDatas.title}}</span>
      <span>{{bottomDatas.text}}</span>
      <ul class="list">
        <li v-for="(item,index) in bottomDatas.bottomData" :key="index">{{item}}</li>
      </ul>
    </div>
    </ViewWrapper>
  </div>
</template>

<script>
import ViewWrapper from '@/components/ViewWrapper'
export default {
  components: {
    ViewWrapper,
  },
  props: {
    bottomDatas: {
      type: Object,
      default: function () {
        return ''
      },
    },
  },
  data () {
    return {
      size: 'is-large',
    }
  },
}
</script>

<style lang="less" scoped>
  .bottom{
    min-width: 1080px;
    background-repeat: no-repeat;
    background-position: center;
    .bottomContent{
      max-width: 1400px;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #FFFFFF;
    padding: 35px 0 50px;
    box-sizing: border-box;
    margin: 0 auto;
      .title{
        display: block;
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
        color: #FFFFFF;
        margin-bottom: 23px;
      }
      .list{
        list-style: disc;
        margin-top: 33px;
        margin-left: 20px;
      }
    }
  }
</style>
