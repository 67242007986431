<template>
  <div class="wrapper">
    <div class="news-container" v-for="(item, index) in newsList" :key="index">
      <div class="news-img">
        <img :src="item.imgSrc" alt="news">
      </div>
      <div class="news-info" @click="lookNewsDetail(index)">
        <div class="title font">{{ item.title }}</div>
        <div class="sub-title font" v-if="item.subTitle">{{ item.subTitle }}</div>
        <p class="desc font">
          {{ item.desc }}
        </p>
        <span class="time font">{{ item.time }}</span>
        <slot name="extra"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsInfo',
  props: {
    newsList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    lookNewsDetail (i) {
      this.$router.push({ path: '/newsDetails', query: { id: i } })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
}

.news-container {
  .g_center();
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 94px;
  padding-bottom: 94px;
  border-bottom: 1px solid #e7e7e7;
  &:last-child {
    border: none;
  }
}

.news-img {
  width: 500px;
  height: 300px;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
}

.news-info {
  width: 800px;
  cursor: pointer;
  margin-left: 109px;
  .font {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
  }
  .title {
    font-size: 32px;
    line-height: 45px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #393939;
    margin-top: 30px;
  }
  .sub-title {
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 27px;
  }
  .desc {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #5B5B5B;
    margin-top: 24px;
  }
  .time {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #ABABAB;
    margin-top: 14px;
  }
}
</style>
