<template>
  <div class="middle">
    <ViewWrapper :size="size">
    <div class="middleContent">
      <div class="top">
        <div class="imgBox">
          <img :src="middleContenData.img" alt="" class="img">
        </div>
        <div class="textContent">
          <div class="text" v-html="middleContenData.text"></div>
          </div>
      </div>
      <div class="middle">行业挑战</div>
      <div class="bottom">
        <div class="item1" v-if="middleContenData.middleData.length > 3">
        <div class="itemList" v-for="(item,index) in middleContenData.middleData " :key="index" >
            <div class="icon">
            <icon :icon="item.icon"/>
          </div>
          <div class="title">{{item.title}}</div>
          <div class="text">{{item.text}}</div>
          </div>
        </div>
        <div class="item2" v-else>
        <div class="itemList" v-for="(item,index) in middleContenData.middleData " :key="index" >
            <div class="icon">
            <icon :icon="item.icon"/>
          </div>
          <div class="title">{{item.title}}</div>
          <div class="text">{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </ViewWrapper>
  </div>
</template>

<script>
import ViewWrapper from '@/components/ViewWrapper'
export default {
  components: {
    ViewWrapper,
  },
  props: {
    middleContenData: {
      type: Object,
      default: function () {
        return ''
      },
    },
  },
  data () {
    return {
      size: 'is-large',
    }
  },
}
</script>

<style lang="less" scoped>
 ::v-deep .svg-icon {
               width: 4em;
               height: 4em;}
  .middle{
    height: 100%;
    min-width: 1080px;
    background: #F5F6F8;
    .middleContent{
      padding: 85px 0 70px;
      box-sizing: border-box;
      .top{
        display: flex;
        margin: 0 auto;
        max-width: 1400px;
        background: #FFFFFF;
        box-shadow: 0px 12px 32px rgba(190, 196, 204, 0.2);
        .imgBox{
          flex: 4;
          .img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .textContent{
          flex: 3;
          max-height: 100%;
          .text{
            margin: 76px 76px 33px 76px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
          }
        }
      }
      .middle{
        max-width: 1400px;
        height: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 36px;
        color: #464646;
        margin: 78px auto 45px ;
      }
      .bottom{
        background: #FFFFFF;
        box-shadow: 0px 12px 32px rgba(190, 196, 204, 0.2);
        box-sizing: border-box;
        align-items: center;
         justify-content: center;
        margin: 0 auto;
        max-width: 1400px;
        .item1{
          display: flex;
         padding: 10px 27px 40px 27px;
          .itemList{
            flex: 1;
          text-align: center;
          margin-top: 50px;
          &:not(:last-child){
            margin-right: 50px;
          }
          .icon{
            margin-bottom: 50px;
            text-align: center;

          }
          .title{
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            margin-bottom: 42px;
          }
          .text{
            font-weight: 400;
            font-size: 16px;
            line-height: 34px;
            color: #666666;
            text-align: center;

          }
        }
        }
        .item2{
          display: flex;
          padding: 10px 50px 40px 50px;
          .itemList{
            flex: 1;
            margin-top: 50px;
          &:not(:last-child){
            margin-right: 100px;
          }
          &:first-child {
            margin-left: 50px;
          }
           &:last-child {
            margin-right: 50px;
           }
          .icon{
            margin-bottom: 50px;
            text-align: center;
          }
          .title{
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            margin-bottom: 42px;
          }
          .text{
            font-weight: 400;
            font-size: 16px;
            line-height: 34px;
            color: #666666;
            text-align: center;

          }
        }
        }

      }

    }
  }
</style>
