<template>
  <div class="content">
    <div class="top"><Top :topData ="homeApplianceIndustry.topData"/></div>
    <div class="navigation"><Navigation :type="homeApplianceIndustry.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="homeApplianceIndustry.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="homeApplianceIndustry.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      homeApplianceIndustry: {
        topData: {
          title: '家电行业',
          text: '家电行业解决方案，依托大数据服务平台和生态应用体系，实现为家电及其上下游企业转型赋能',
        },
        middleContenData: {
          img: '/programme/homeApplianceIndustry1.png',
          text: '智能家电正在成为最新的热点，消费者对高端、智能的家电需求将成为行业趋势。我们国家的家电生产企业将会朝着智能制造的方向发展。‘智造云+工业云’是企业数字化转型方向，智能制造突破要瞄准“智能产品、智能制造、智能服务”三个功能系统和“智能制造云、工业互联网云”两个支撑系统。<br/> <br/> 家电数字工业化也必须从“工业互联网平台、虚拟仿真平台、模块化/柔性化生产线、在线自适应检测装备、智能立体仓库”等方面下手。',
          middleData: [{
            icon: 'ic_important',
            title: '同质化竞争',
            text: '更新换代比较快，企业间产品同质化竞争、家族式粗犷管理模式带来众多弊端',
          },
          {
            icon: 'ic_services1',
            title: '信息化程度低',
            text: '除龙头企业有部署基础ERP系统外，企业普遍手工做账，信息化程度低',
          },
          {
            icon: 'ic_jiadian03',
            title: '缺乏产品研发',
            text: '企业缺乏资金进行规模扩张和产品研发，有信息化部署的企业仅使用进销存功能模块',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: ' 通过开放API和开发者平台，实现各类应用系统集成和工业APP开发，通过IoT数据赋能各类业务系统，实现业务系统间数据互联互通',
          bgImg: '/programme/homeApplianceIndustry2.png',
          bottomData: [
            '工业APP', '数据采集', 'loT平台',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
