<template>
  <div class="content">
    <div class="top"><Top :topData ="industrialCluster.topData"/></div>
    <div class="navigation"><Navigation :type="industrialCluster.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="industrialCluster.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="industrialCluster.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      industrialCluster: {
        topData: {
          title: '产业集群',
          text: '西图盟通过对集群内企业和供应链的持续深入服务，积累并沉淀一批行业共性机理模型，建立该产业集群模型库，并建立集群共建共享机制。',
        },
        middleContenData: {
          img: '/programme/industrialCluster1.png',
          text: '传统产业集群的数字化转型升级和数字化赋能新兴产业集群的规划建设已成为实现高质量发展的重要路径。高质量发展的根本目的是共享，其最终导向和目标是共同富裕。产业集群也是中小企业可持续发展的重要载体。<br/> <br/> 以数字经济促进产业集群中的大中小微企业协同发展，对推动创新发展和共同富裕发挥着至关重要的作用。',
          middleData: [{
            icon: 'ic_services1',
            title: '场景各不相同',
            text: '各产业集群发展的痛点场景的不同，其数字化转型具体方案和路径也应有所不同',
          },
          {
            icon: 'ic_performance1',
            title: '发展水平参差不齐',
            text: '集群中不同企业的数字化程度各不相同，很难统一规划',
          },
          {
            icon: 'ic_canyejiqun03',
            title: '转型路径不同',
            text: '不同企业对数字化转型规划路径差异较大',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: '从供应链视角贯穿企业上下游，将企业及供应商的制造、供应流程打通，整合生产计划、供应计划、产品质量、物流与执行全流程，按企业生产标准辐射至供应商，达到市场需要与生产计划强匹配，产业链上下游信息同步。',
          bgImg: '/programme/industrialCluster2.png',
          bottomData: [
            'ICT', '信息同步', '整合生产计划',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
