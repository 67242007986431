<template>
  <div class="content">
    <div class="top"><Top :topData ="carIndustry.topData"/></div>
    <div class="navigation"><Navigation :type="carIndustry.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="carIndustry.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="carIndustry.bottomDatas" /></div>
   <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      carIndustry: {
        topData: {
          title: '汽车行业',
          text: '西图盟通过MES解决方案赋能汽车生产，通过数字孪生技术让车间管理实现数字化。',
        },
        middleContenData: {
          img: '/programme/carIndustry1.png',
          text: '汽车行业对于生产管理有着非常高的要求，能够辐射其他制造行业。因为产品本身的复杂，带来了现场生产管理、设备管理、仓储管理、供应商管理、物流管理等等各个方面的严峻挑战。为了解决这些挑战，汽车行业不得不持续改进生产管理方式。因此，从汽车行业兴起以来，制造业中最先进的管理体系一直都是在汽车行业之中首创。<br/> <br/> 汽车行业是一个高度综合的行业，会带来几乎整条产业链。它的上游行业几乎囊括所有制造业部门——冶金、电子、化工等。它也是个重资产行业，对于库存十分敏感。库存量意味着积压的资金。所以大部分车厂都在为了控制库存量而不断做数字化改进。这也是西图盟擅长的解决方案。',
          middleData: [{
            icon: 'ic_delivery1',
            title: '行业信息水平分化',
            text: '行业整体信息化程度仍然较低，综合性行业龙头已开始搭建云平台，但细分行业龙头仍处于应用单个系统阶段，腰部企业很多仍未上MES',
          },
          {
            icon: 'ic_services1',
            title: '管理市场、研发端系统阻滞',
            text: '客户验厂频繁，对生产管理流程、数据报表质量要求高；多个系统不打通，数据采集不及时；数据展示不够直观，数据资源利用深度不足 ',
          },
          {
            icon: 'ic_performance1',
            title: '生产端质量问题难追溯',
            text: '产品质量问题难以防控与追溯，设备、道具管理粗放，排产不智能，生产进度不透明，员工绩效难以量化，库存更新不及时',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: ' 产线WMS，全程二维码/条码、位置管理、批次管理，实现先进先出，实时收集数据和库存，操作可追溯。',
          bgImg: '/programme/carIndustry2.png',
          bottomData: [
            '数据收集', '数据追溯', '仓库管理系统（WMS）',
          ],
        },
      },
      size: 'is-large',
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
