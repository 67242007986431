<template>
  <div class="content">
    <div class="top"><Top :topData ="chemicalIndustry.topData"/></div>
    <div class="navigation"><Navigation :type="chemicalIndustry.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="chemicalIndustry.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="chemicalIndustry.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      chemicalIndustry: {
        topData: {
          title: '化工行业',
          text: '西图盟化工行业安全生产及风险应急云平台，实现化工企业的生产一屏统管，安全一屏感知',
        },
        middleContenData: {
          img: '/programme/chemicalIndustry1.png',
          text: '企业信息化建设覆盖从底层基础设施到上层应用软件，具备即需即用、按需付费的服务正在给化工行业带来前所未有的变革，依附SaaS模式，大型企业、集团搭建S2B2C供应链系统商城，已成为许多化工企业发展及建设的方向。<br/> <br/> 化工企业可通过第三方技术服务商数商云搭建化工S2B2C供应链电商平台系统，赋能化工产业全链路数字化，SaaS模式下的S2B2C平台可为企业提供标准化产品和服务。S2B2C系统商城基于对新商业未来的思考，降低运营成本，提升整体效率，助力企业避免同质化，增强核心竞争力。',
          middleData: [{
            icon: 'ic_hg01',
            title: '信息系统参差不齐',
            text: '现有化工企业基本实现复杂工艺的自动控制和连续生产，但是想对应的生产管控系统，安全风险预警系统等系统的建设参差不齐',
          },
          {
            icon: 'ic_hg02',
            title: '安全管控落实难',
            text: '化工企业产业链较长、厂内危险化学品数量多、布局分散，风险源、危险源数量多而散，应急预案不完善、数字化程度低，造成应急反应时间延长',
          },
          {
            icon: 'ic_hg03',
            title: '设备管理体系不完善',
            text: '化工企业设备资产较多、检修/维修、点检管理缺乏完整数据闭环，分析无从下手，设备普遍缺乏预测性维护，系统间的数据缺乏桥梁',
          },
          {
            icon: 'ic_hg04',
            title: '数字贯穿难',
            text: '集团对二三级公司的数据未形成有效互联互通及管控机制，厂内生产经营数据量大，散落在各个层级的异构系统中，决策缺乏实时依据',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: '西图盟基于数据中台，针对化工企业，梳理厂内的生产数据指标，支撑厂内生产运营驾驶舱的建设，形成化工企业的标准化指标体系，形成数据资源目录，包含生产、采购、库存、销售、环保、设备、等。',
          bgImg: '/programme/chemicalIndustry2.png',
          bottomData: [
            '数据中台', ' 驾驶舱建设',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
