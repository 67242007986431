<template>
  <div>
    <banner-wrapper :wrapperText="wrapperText" />
    <NewsInfo :newsList="newsList" />
  </div>
</template>

<script>
import BannerWrapper from '@/components/BannerWrapper/BannerWrapper'
import NewsInfo from '@/components/NewsInfo/NewsInfo'

export default {
  name: 'news',
  components: {
    BannerWrapper,
    NewsInfo,
  },
  data () {
    return {
      wrapperText: {
        title: '新闻资讯',
        desc: '与西图盟一起深度挖掘开发工业互联网世界',
        imgSrc: require('@/assets/news/news_banner.jpg'),
      },
      newsList: [
        {
          imgSrc: require('@/assets/news/news_1.png'),
          title: '国家数字化设计与制造创新中心揭牌',
          desc: '10月25日，国家数字化设计与制造创新中心启动会及揭牌仪式举行，会议由湖北省经信委主任王祺扬主持。',
          time: '2018-10-29',
        },
        {
          imgSrc: require('@/assets/news/news_2.jpg'),
          title: '省级制造业创新中心，3家由杭企牵头打造',
          desc: '10月31日，浙江省经济和信息化厅和浙江省财政厅联合发布2019年第167号文件，公布了2019年浙江省制造业创新中心创建名单。',
          time: '2019-11-05',
        },
        {
          imgSrc: require('@/assets/news/news_3.jpg'),
          title: '2019年浙江省制造业创新中心创建名单新鲜出炉！',
          desc: '为深入实施数字经济“一号工程”，进一步完善浙江制造业创新体系，全面提升自主创新能力，加快制造强省建设，省财政厅、省经信厅联合开展2019年浙江省制造业创新中心培养创建工作。',
          time: '2019-11-12',
        },
      ],
    }
  },
}
</script>
