<template>
  <div class="content">
    <div class="top"><Top :topData ="industryGeneral.topData"/></div>
    <div class="navigation"><Navigation :type="industryGeneral.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="industryGeneral.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="industryGeneral.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      industryGeneral: {
        topData: {
          title: '行业通用',
          text: '西图盟为离散制造业赋能：降本、创收、辅助客户运营',
        },
        middleContenData: {
          img: '/programme/industryGeneral1.png',
          text: '数字化要求更快的响应速度，由此必然带来效率的提升。信息化只是通过将数据录入到计算机，有限提升工作效率；而数字化直接完成了业务的在线化，使许多工作都可以交由机器自动完成，从而在最大幅度上降低失误、提升效率。<br/> <br/> 数字化推动了企业运营模式的颠覆式变革。基于网络，企业可以直接与客户建立连接，建立真正以客户为中心的运营模式。',
          middleData: [{
            icon: 'ic_delivery1',
            title: '效率统计落后',
            text: '设备效率的统计采用传统人工统计方式，准确度、可信度不高',
          },
          {
            icon: 'ic_hg03',
            title: '能耗管理差',
            text: '能耗设备多，能耗量大，费用高，且能耗流向不明',
          },
          {
            icon: 'ic_hg04',
            title: '设备利用率低',
            text: '管理粗放，设备利用率不高',
          },
          {
            icon: 'ic_danyi',
            title: '自动化程度低',
            text: '设备繁多，工业协议复杂，大量设备无控制器，无法实现数据采集',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: '设备数据透明化解决方案∶以物联能力为支撑，连接全场景设备，建立以设备指标为基础的数据透明化',
          bgImg: '/programme/industryGeneral2.png',
          bottomData: [
            '透明工厂', '云平台', '设备数据',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
