<template>
  <div class="navigation">
    <div class="navigationContent">
      <div class="titleList" v-for="(item,index) in navigationData" :key="index" @click="$router.push(item.router)">
        <div class="title">{{item.title}}</div>
        <div class="line" v-if="type === item.title"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      navigationData: [{
        title: '汽车行业',
        router: '/carIndustry',
      },
      {
        title: '化工行业',
        router: '/chemicalIndustry',
      },
      {
        title: '物流行业',
        router: '/logisticsIndustry',
      },
      {
        title: '家电行业',
        router: '/homeApplianceIndustry',
      },
      {
        title: '设备制造',
        router: '/deviceFabrication',
      },
      {
        title: '政务服务',
        router: '/governmentService',
      },
      {
        title: '产业集群',
        router: '/industrialCluster',
      },
      {
        title: '行业通用',
        router: '/industryGeneral',
      }],
    }
  },
}
</script>

<style lang="less" scoped>
  .navigation{
    // width: 100%;
    min-width: 1140px;
    height: 76px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
    .navigationContent{
      padding: 24px 0px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      .titleList{
        display:inline-block;
        &:not(:last-child){
          margin-right: 56px;
        }
        .title{
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #000000;
        }
        .line{
          margin-top: 20px;
          margin-left: 9px;
          width: 60px;
          height: 4px;
          background: #0057FF;
        }
      }
    }
  }
</style>
