<template>
  <div class="content">
    <div class="top"><Top :topData ="logisticsIndustry.topData"/></div>
    <div class="navigation"><Navigation :type="logisticsIndustry.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="logisticsIndustry.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="logisticsIndustry.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      logisticsIndustry: {
        topData: {
          title: '物流行业',
          text: '西图盟通过“互联网+物流”的创新模式，建设全智能化物流平台，重塑物流行业格局，助力传统物流企业实现降本增效。',
        },
        middleContenData: {
          img: '/programme/logisticsIndustry1.png',
          text: '通过模拟和虚拟现实、计算智能、计算机网络、数据库、多媒体和信息等技术支持，对物流相关对象和活动进行表现、处理和控制，对物流系统进行信息化、网络化、智能化为特征，促进物流的集成化和智能化。<br/> <br/> 物流数字化技术涉及计算机硬件、软件、信息存储、通信协议、外围设备和互联网等技术手段，信息离散化、表示、扫描、处理、存储、传输、感测、执行、物化、支持、集成、网络需要在托业等领域集合科学技术。数字物流将物流的整个过程数字化说明，将物流系统处理得更加高效、可靠、复杂，为人们提供便利、快捷的物流服务。',
          middleData: [{
            icon: 'ic_hatching',
            title: '仓储智能化水平低',
            text: '订单渠道多、体量大，订单的分配、调度、揽收、配送等多种操作需要花费大量的时间与人力，大量依赖人工',
          },
          {
            icon: 'ic_services1',
            title: '各环节数据不互通',
            text: '无法及时有效互通，导致企业各个环节的信息沟通成本高，信息不对称，数据的可视与透明问题严重 ',
          },
          {
            icon: 'ic_development',
            title: '运输缺乏监管与整合',
            text: '运输过程不可视物流运输参与角色多、流程长且难监管，运输过程不透明、不可控，导致运输成本居高不下，效率难以提升',
          },
          {
            icon: 'ic_juece',
            title: '缺少决策支撑',
            text: '各渠道各类型的数据种类多、数量大，无法对现有运营数据进行分析与辅助决策，无法帮助物流企业不断提高运营效率与服务质量',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: '  搭建完整的物流数字化管理平台。打通物流各环节数据数据壁垒。在物流运输和配送环节，借助物联网、大数据、智能硬件等技术与设备，提高物流系统智能分析决策和智能执行能力。',
          bgImg: '/programme/logisticsIndustry2.png',
          bottomData: [
            'AI', 'IOT', ' 智能运维',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
