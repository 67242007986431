<template>
  <div class="content">
    <div class="top"><Top :topData ="deviceFabrication.topData"/></div>
    <div class="navigation"><Navigation :type="deviceFabrication.topData.title"/></div>
    <div class="middle"><Middle :middleContenData ="deviceFabrication.middleContenData" /></div>
    <div class="bottom"><Bottom :bottomDatas ="deviceFabrication.bottomDatas" /></div>
    <ViewFooter/>
  </div>
</template>

<script>
import Top from '../../components/top'
import Middle from '../../components/middle'
import Bottom from '../../components/bottom'
import Navigation from '../../components/navigation'
import ViewFooter from '@/components/ViewFooter'
export default {
  components: {
    Top,
    Middle,
    Bottom,
    Navigation,
    ViewFooter,
  },
  data () {
    return {
      deviceFabrication: {
        topData: {
          title: '设备制造',
          text: '西图盟基于工业互联网平台+自动化装备及流程软件，新型工业APP支撑的完整解决方案，帮助设备制造企业达成高质量生产',
        },
        middleContenData: {
          img: '/programme/deviceFabrication1.png',
          text: '在“双循环+双碳”新发展格局下，设备制造产业面临大变革，行业企业提供的产品需要具有更短的交货期、更高的质量、更强大的功能，同时伴随全球数字化、智能化发展的浪潮，对工程机械及高端装备产品的个性化、智能化要求也越来越高，这给装备制造企业的转型带来了巨大挑战。<br/> <br/> 只有贯穿企业的生产计划、生产管理、现场管控等全方位的数字化建设，才能更高效的实现企业的“专业化”设计、“精细化”管理、“特色化”的产品以及业务“新模式”的创新。',
          middleData: [{
            icon: 'ic_delivery1',
            title: '运营管控问题',
            text: '已有基本的精益意识，但局限于点状改进，效益提升有限核心经营指标提升遇到瓶颈，牵一发动全身生产运营过程不透明，数据不准确，异常反应慢，试错成本高',
          },
          {
            icon: 'ic_services1',
            title: '技术基础问题',
            text: '产制造、质检设备普遍非智能化，不能自动采集、整体采集，数据主要靠人工记录很难接触到适用的工业技术，工业Al、工业视觉、边缘计算等技术，停留在单点引入速度慢、成本高、效果差',
          },
          {
            icon: 'ic_yewusz',
            title: '业务&数字化能力',
            text: '信息存在断点，数字化业务渗透不全面仍要依靠人工，数据连接和融合计算能力不足不规范，数字化标准未制定或落实不到位烟囱式架构，反向阻碍业务流程迭代和进步',
          }],
        },
        bottomDatas: {
          title: '解决方案：',
          text: ' 实现机加工设备的集中控制管理，数据集中共享，加工程序及指令准确下达实现生产数据、设备数据的集中采集与监控采取预防性维护手段，不再依赖静态的维修计划，合理安排作业流程',
          bgImg: '/programme/deviceFabrication2.png',
          bottomData: [
            '制造运营管理', '高级计划排程', '设备健康',
          ],
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding-bottom: 50px;
    box-sizing: border-box;
    background:  #fff;
  }
</style>
