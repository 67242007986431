<template>
  <div>
    <banner-wrapper :wrapperText="wrapperText" />
    <Details :id="$route.query.id" />
  </div>
</template>

<script>
import BannerWrapper from '@/components/BannerWrapper/BannerWrapper'
import Details from './components/Details'

export default {
  name: 'newsDetails',
  components: {
    BannerWrapper,
    Details,
  },
  data () {
    return {
      wrapperText: {
        title: '新闻资讯',
        desc: '与西图盟一起深度挖掘开发工业互联网世界',
        imgSrc: require('@/assets/news/news_info.jpg'),
      },
    }
  },
}
</script>
